<div class="search-view">
  <mat-drawer-container class="search-view__drawer-container">
    <mat-drawer
      class="search-view__drawer"
      [disableClose]="true"
      [mode]="'side'"
      [opened]="true"
    >
      @if (filterMenuV2) {
        <app-search-filter-menu-v2
          [searchContainer]="searchContainer"
        />
      }
      @else {
        <app-search-focus-menu
          *ngIf="!isFolder"
          [searchContainer]="searchContainer"></app-search-focus-menu>

        <app-search-filter-menu
          [sideMenu]="true"
          [searchContainer]="searchContainer"
        ></app-search-filter-menu>
      }
    </mat-drawer>

    <mat-drawer-content class="search-view__drawer-content">
      <app-search-header
        [searchContainer]="searchContainer"
      />

      @if (searchContainer.queryContainer.selectedQueryMenu?.query_type === 'advanced') {
        <app-advanced-search-query-builder
          [searchContainer]="searchContainer"
        />
      }

      <app-search-filter-chiplist
        [searchContainer]="searchContainer"
      />

      <app-search-hierarchic-bread-crumbs
        [searchContainer]="searchContainer"
        [targetId]="searchContainer.targetId"
      />

      <div #firstSibling class="search-view__menus-container">
        <app-search-menus
          [searchContainer]="searchContainer"
        />
      </div>

      @if (!isFolder) {
        <app-search-view-full-header
          [searchContainer]="searchContainer"
          [listFieldTemplateId]="searchContainer.listFieldTemplateId"
        />
      }

      @if (isFolder && searchContainer.targetObject) {
        <app-search-view-folder-header
          [searchContainer]="searchContainer"
          [folderId]="searchContainer.targetObject.artifact_id"
        />
      }

      <div #secondSibling (scroll)="onScroll()" class="search-view__result-container">
        @if (searchContainer.searching) {
          <div class="result-container__loading">
            <mat-spinner
              color="accent"
              diameter="60"
            />
            <span>
              {{ 'TRANS__SEARCH__LOADING_INFO' | translate }}
            </span>
          </div>
        }
        @else {

            <app-search-result-view-v2
              [searchContainer]="searchContainer"
            />

        }
      </div>
      <app-search-view-pagination
          [searchContainer]="searchContainer"
        />
    </mat-drawer-content>
  </mat-drawer-container>
</div>
