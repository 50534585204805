import {Component, OnDestroy} from '@angular/core';
import {WidgetContainerComponent} from '../widget-container/widget-container.component';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FeatureFlagsService} from '../../core/feature-flags.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatCheckbox} from '@angular/material/checkbox';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {
  MatActionList,
  MatListItem,
  MatListItemLine,
  MatListItemMeta,
  MatListItemTitle,
  MatSelectionList
} from '@angular/material/list';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'app-feature-flags-widget',
  standalone: true,
  imports: [
    WidgetContainerComponent,
    FormsModule,
    MatButton,
    MatCheckbox,
    ReactiveFormsModule,
    TranslateModule,
    MatSelectionList,
    MatListItem,
    MatListItemTitle,
    MatListItemLine,
    MatActionList,
    MatSlideToggle,
    MatListItemMeta,
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu
  ],
  templateUrl: './feature-flags-widget.component.html',
  styleUrl: './feature-flags-widget.component.scss'
})
export class FeatureFlagsWidgetComponent implements OnDestroy {

  useNewObjectPageFC = this.fb.control(this.featureFlagService.getFeatureFlags()?.experimental?.useNewObjectPage);
  useNewFilterMenuFC = this.fb.control(this.featureFlagService.getFeatureFlags()?.experimental?.useNewFilterMenu);
  useNewMainMenuFC = this.fb.control(this.featureFlagService.getFeatureFlags()?.experimental?.useNewMainMenu);
  private subscriptions: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private featureFlagService: FeatureFlagsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    this.featureFlagService.initFeatureFlags().then(() => {
      this.initFlags();

      this.subscriptions.push(
        this.useNewObjectPageFC.valueChanges.subscribe(() => {
          this.saveFlags();
        }),
        this.useNewFilterMenuFC.valueChanges.subscribe(() => {
          this.saveFlags();
        }),
        this.useNewMainMenuFC.valueChanges.subscribe(() => {
          this.saveFlags();
        })
      )

    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  // form = this.fb.group({
  //   temporary: this.fb.group({}), experimental: this.fb.group({
  //     useNewObjectPage: this.featureFlagService.getFeatureFlags()?.experimental?.useNewObjectPage,
  //     useNewFilterMenu: this.featureFlagService.getFeatureFlags()?.experimental?.useNewFilterMenu
  //   }), permanent: this.fb.group({})
  // })

  async initFlags() {
    this.useNewObjectPageFC.setValue(this.featureFlagService.getFeatureFlags()?.experimental?.useNewObjectPage);
    this.useNewFilterMenuFC.setValue(this.featureFlagService.getFeatureFlags()?.experimental?.useNewFilterMenu);
    this.useNewMainMenuFC.setValue(this.featureFlagService.getFeatureFlags()?.experimental?.useNewMainMenu);
  }

  saveFlags() {
    this.featureFlagService.setOverrides({
      experimental: {
        useNewFilterMenu: this.useNewFilterMenuFC.value,
        useNewObjectPage: this.useNewObjectPageFC.value,
        useNewMainMenu: this.useNewMainMenuFC.value
      }
    })
    this.notify(this.translate.instant('TRANS__FEATURE_FLAGS_WIDGET__NOTIFY_SAVED'));
  }

  async resetFlags() {
    this.featureFlagService.clearOverrides();
    this.initFlags();
    this.notify(this.translate.instant('TRANS__FEATURE_FLAGS_WIDGET__NOTIFY_RESET'));
  }

  private notify(message: string) {
    this.snackBar.open(message, null, {
      duration: 3000,
    })
  }


}
