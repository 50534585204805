import {Component, Input, ElementRef, ViewChild} from '@angular/core';
import {SearchContainer} from "../../core/definitions/search-container";
import {FeatureFlagsService} from "../../core/feature-flags.service";

@Component({
  selector: 'app-search-view-full-v2',
  templateUrl: './search-view-full-v2.component.html',
  styleUrl: './search-view-full-v2.component.scss'
})
export class SearchViewFullV2Component {

  @ViewChild('firstSibling') firstSibling!: ElementRef;
  @ViewChild('secondSibling') secondSibling!: ElementRef;

  @Input() searchContainer: SearchContainer;
  @Input() searchViewType: string;

  onScroll(): void {
    if (this.secondSibling.nativeElement.scrollTop > 0) {
      this.firstSibling.nativeElement.style.borderBottom = '1px solid var(--sys-outline-variant)';
    } else {
      this.firstSibling.nativeElement.style.borderBottom = 'none';
    }
  }

  isFolder: boolean = false;

  constructor(
    private featureFlagService: FeatureFlagsService
  ) {}

  readonly filterMenuV2 = this.featureFlagService.getFeatureFlags().experimental.useNewFilterMenu;
}
