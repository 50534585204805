<div
  class="thumbnail-item"
  [ngClass]="{'thumbnail-item--selected' : searchContainer.selections.selected[item.artifact_id]}"
  [routerLink]="getRouterLink(item)"
>
  <div
    class="thumbnail-item__checkbox-container"
    [ngClass]="{'thumbnail-item__checkbox-container--selected' : searchContainer.selections.selected[item.artifact_id]}"
  >
    <mat-checkbox
      (click)="select(item, $event)"
      [checked]="searchContainer.selections.selected[item.artifact_id]"
    />
  </div>

  @if(item.hasOwnProperty('$$imageUrl')) {
    <img
      [alt]=""
      class="thumbnail-item__image"
      [src]="item.$$imageUrl"
    />
  }
  @else {
    <div class="thumbnail-item__placeholder-container">
      <mat-icon class="thumbnail-item__placeholder">
        {{ getMaterialSymbol(item.object_type) }}
      </mat-icon>
    </div>
  }

  <div class="thumbnail-item__content">
    <mat-icon class="thumbnail-item__icon">
      {{ getMaterialSymbol(item.object_type) }}
    </mat-icon>

    <div class="thumbnail-item__text">
      <span class="thumbnail-item__title">{{item.artifact_name}}</span>
      @for (label of item.overview_simple; track $index) {
        <span class="thumbnail-item__description">{{label.value}}</span>
      }
    </div>
  </div>
</div>
