@if(objectFields) {
  <table mat-table [dataSource]="dataSource" [fixedLayout]="true">
    <ng-container matColumnDef="artifact_name" [sticky]="true">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        <mat-checkbox
          (click)="selectAll(checkedStatus() === 'all')"
          [checked]="checkedStatus() === 'all'"
          [indeterminate]="checkedStatus() === 'some'"
        />
        <span>
          {{'TRANS__FIELD__NAME' | translate}}
        </span>
      </th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        <div class="list__name-column">
          <mat-checkbox
            (click)="select(row, $event)"
            [checked]="searchContainer.selections.selected[row[AConst.ARTIFACT_ID]]"
            [disabled]="row.used"
          />

          <button
            mat-icon-button
            (click)="treeControl.toggle(row)"
            [style.visibility]="!isExpandable(row) ? 'hidden' : 'visible'"
          >
            @if(!row['isLoading']) {
              <mat-icon>
                {{treeControl.isExpanded(row) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            }
            @else {
              <mat-spinner
                diameter="16"
              />
            }
          </button>

          <div>
            <a
              queryParamsHandling="merge"
              [queryParams]="row['refData']['queryParams']"
              [routerLink]="getRouterLink(row)"
            >
              <mat-icon>
                {{ iconToMaterialSymbol(row.icon) }}
              </mat-icon>
              <span [innerHTML]="row.name"></span>
            </a>
          </div>
        </div>
      </td>
    </ng-container>

    @for (column of searchContainer.currentPathView.search_view.overview_fields; track $index) {
      <ng-container [matColumnDef]="column.$$fieldColumnName">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
        <span>
          {{ column.$$fieldTitle }}
        </span>
        </th>

        <td
          mat-cell
          *matCellDef="let row"
        >
          @for(field of getObjectFields(row); track $index) {
            <div [title]="field.title">
              @if(column.$$fieldColumnName === field.$$fieldColumnName) {
                <app-overview-field-value
                  [object]="row.item"
                  [viewName]="searchContainer.searchResultViewName"
                  [overviewField]="field"
                  [metaField]="column"
                />
              }
            </div>
          }
        </td>
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
}
