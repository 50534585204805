<mat-expansion-panel [class.elevation-z]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="job-item__header">
        <div class="job-item__header-title-container">
          <div>
            <span>
              {{ message.task_name | translate}}
            </span>

            <span>
              {{ message.fileTypeName }}
            </span>
          </div>

          <div>
            <span class="job-item__header-subtitle">
              {{ message.taskMetaData }}
            </span>
          </div>
        </div>

        <div class="job-item__header-status">
          @switch (message.statusType) {
            @case ('active') {
              <mat-spinner
                diameter="20"
              />
            }

            @case ('failed') {
              <mat-icon class="job-item__icon job-item__icon--failed">
                close
              </mat-icon>
            }

            @case ('finished') {
              <mat-icon class="job-item__icon job-item__icon--finished">
                done
              </mat-icon>
            }
          }

          @if (message.statusType === 'failed') {
            <a>
              <span>{{'TRANS__JOB_STATUS__FAILED' | translate}}</span>
            </a>
          }
          @else {
            <span>
              {{ message.statusMessage }}
            </span>
          }
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div>
    <div>
      <span>
        ID: {{ message.queue_id }}
      </span>
    </div>

    @if (message.task_type === 'reports' && (message.status === 'finished' || message.status === 'downloaded')) {
      <button
        mat-stroked-button
      >
        <span>
          {{ 'TRANS__JOB_STATUS__DOWNLOAD' | translate}}
        </span>
      </button>

      @if (message.ref) {
        <a
          mat-stroked-button
          color="primary"
          queryParamsHandling="merge"
          [routerLink]="primusStateMapperService.routerLinkFromState(message.ref)"
          [queryParams]="primusStateMapperService.queryParams(message.refParams)"
        >
          <span>{{'TRANS__JOB_STATUS__OPEN' | translate}}</span>
        </a>
      }
    }
  </div>
</mat-expansion-panel>
