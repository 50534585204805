import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';

import {SharedModule} from '../shared/shared.module';
import {SearchQueryComponent} from './search-query/search-query.component';
import {SearchFilterMenuComponent} from './search-filter-menu/search-filter-menu.component';
import {SearchFilterMenuPathComponent} from './search-filter-menu/search-filter-menu-path/search-filter-menu-path.component';
import {SearchFilterMenuItemComponent} from './search-filter-menu/search-filter-menu-item/search-filter-menu-item.component';
import {SearchFilterGroupComponent} from './search-filter-menu/search-filter-group/search-filter-group.component';
import {DateSearchFieldComponent} from './date-search-field/date-search-field.component';
import {SearchFilterMenuSmallComponent} from './search-filter-menu/search-filter-menu-small/search-filter-menu-small.component';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import {SearchResultViewComponent} from './search-result-view/search-result-view.component';
// eslint-disable-next-line max-len
import {SearchResultViewThumbnailComponent} from './search-result-view/search-result-view-thumbnail/search-result-view-thumbnail.component';
// eslint-disable-next-line max-len
import {SearchResultViewListSelectorComponent} from './search-result-view/search-result-view-list-selector/search-result-view-list-selector.component';
// eslint-disable-next-line max-len
import {SearchResultViewListThumbnailComponent} from './search-result-view/search-result-view-list-thumbnail/search-result-view-list-thumbnail.component';
import {SearchResultViewListComponent} from './search-result-view/search-result-view-list/search-result-view-list.component';
import {SearchMenusComponent} from './search-menus/search-menus.component';
import {SearchMenuSortComponent} from './search-menus/search-menu-sort/search-menu-sort.component';
import {SearchMenuViewSelectorComponent} from './search-menus/search-menu-view-selector/search-menu-view-selector.component';
import {SearchMenuFolderSettingsComponent} from './search-menus/search-menu-folder-settings/search-menu-folder-settings.component';
import {SearchMenuActionsComponent} from './search-menus/search-menu-actions/search-menu-actions.component';
// eslint-disable-next-line max-len
import {SearchMenuSelectAllComponent} from './search-menus/search-menu-select-all/search-menu-select-all.component';
// eslint-disable-next-line max-len
import {SearchMenuToggleRestrictionsComponent} from './search-menus/search-menu-toggle-restrictions/search-menu-toggle-restrictions.component';
import {SearchMenuSelectChosenComponent} from './search-menus/search-menu-select-chosen/search-menu-select-chosen.component';
import {SearchResultPagerComponent} from './search-result-view/search-result-pager/search-result-pager.component';
import {SearchResultPagerListComponent} from './search-result-view/search-result-pager-list/search-result-pager-list.component';
import {SearchViewSelectorComponent} from './search-view-selector/search-view-selector.component';
import {SearchHeaderComponent} from './search-header/search-header.component';
import {SideMenuToggleComponent} from './search-header/side-menu-toggle/side-menu-toggle.component';
import {SearchFocusMenuComponent} from './search-menus/search-focus-menu/search-focus-menu.component';
import {SearchFocusEditDialogComponent} from './search-menus/search-focus-edit-dialog/search-focus-edit-dialog.component';
import {SearchViewFullComponent} from './search-view-full/search-view-full.component';
import {SearchPageComponent} from './search-page/search-page.component';
import {SearchQueryPathMenuComponent} from './search-query/search-query-path-menu/search-query-path-menu.component';
import {SearchResultViewGalleryComponent} from './search-result-view/search-result-view-gallery/search-result-view-gallery.component';
import {SearchViewFolderHeaderComponent} from './search-view-full/search-view-folder-header/search-view-folder-header.component';
import {SearchViewFullHeaderComponent} from './search-view-full/search-view-full-header/search-view-full-header.component';
// eslint-disable-next-line max-len
import {SearchSelectionBasketComponent} from './search-menus/search-selection-basket/search-selection-basket.component';
// eslint-disable-next-line max-len
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {SearchFocusMenuPanelComponent} from './search-menus/search-focus-menu-panel/search-focus-menu-panel.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {OperationsModule} from '../operations/operations.module';
import {PanelLayoutComponent} from './advanced-search-and-search-category/components/panel-layout/panel-layout.component';
import {ContentSectionComponent} from './advanced-search-and-search-category/components/content-section/content-section.component';
import {AdvancedSearchComponent} from './advanced-search-and-search-category/advanced-search/advanced-search.component';
import {SearchCategoryComponent} from './advanced-search-and-search-category/search-category/search-category.component';
import {FieldFilterComponent} from './advanced-search-and-search-category/components/field-filter/field-filter.component';
import {FieldListComponent} from './advanced-search-and-search-category/advanced-search/field-list/field-list.component';
import {QueryBuilderComponent} from './advanced-search-and-search-category/advanced-search/query-builder/query-builder.component';
import {InputComponent} from './advanced-search-and-search-category/advanced-search/input/input.component';
import {TextInputComponent} from './advanced-search-and-search-category/advanced-search/input/text-input/text-input.component';
import {DateInputComponent} from './advanced-search-and-search-category/advanced-search/input/date-input/date-input.component';
import {NumberInputComponent} from './advanced-search-and-search-category/advanced-search/input/number-input/number-input.component';
import {BooleanInputComponent} from './advanced-search-and-search-category/advanced-search/input/boolean-input/boolean-input.component';
import {SelectInputComponent} from './advanced-search-and-search-category/advanced-search/input/select-input/select-input.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AdvancedSearchResultViewComponent} from './advanced-search-result-view/advanced-search-result-view.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { SelectObjectTypeButtonComponent } from './advanced-search-and-search-category/components/select-object-type-button/select-object-type-button.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSortModule} from '@angular/material/sort';
import { SearchFilterHierarchicComponent } from './search-filter-menu/search-filter-hierarchich/search-filter-hierarchic.component';
import { SearchFilterHierarchyComponent } from './search-filter-menu/search-filter-hierarchich/search-filter-hierarchy/search-filter-hierarchy.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTreeModule} from '@angular/material/tree';
import { SearchHierarchicBreadCrumbsComponent } from './search-view-full/search-hierarchic-bread-crumbs/search-hierarchic-bread-crumbs.component';
import {MatTabsModule} from '@angular/material/tabs';
import {ObjectNavigatorComponent} from './object-search-menu/object-navigator/object-navigator.component';
import {SearchCollapseComponent} from './object-search-menu/search-collapse/search-collapse.component';
import {ObjectSearchMenuComponent} from './object-search-menu/object-search-menu.component';
import { SearchFacetRangeComponent } from './search-filter-menu/search-facet-range/search-facet-range.component';
import { SearchQueryFieldSearchComponent } from './search-query/search-query-field-search/search-query-field-search.component';
import { SearchQueryAdvancedSearchComponent } from './search-query/search-query-advanced-search/search-query-advanced-search.component';
import { AdvancedSearchQueryBuilderComponent } from './advanced-search-query-builder/advanced-search-query-builder.component';
import { AdvancedSearchQueryBuilderGroupComponent } from './advanced-search-query-builder/advanced-search-query-builder-group/advanced-search-query-builder-group.component';
import { AdvancedSearchQueryBuilderFieldComponent } from './advanced-search-query-builder/advanced-search-query-builder-field/advanced-search-query-builder-field.component';
import {
    SearchFilterChiplistComponent
} from './search-view-full/search-filter-chiplist/search-filter-chiplist.component';
import { AdvancedSearchQueryBuilderInformationDialogComponent } from './advanced-search-query-builder/advanced-search-query-builder-information-dialog/advanced-search-query-builder-information-dialog.component';
import { AdvancedSearchQueryBuilderOptionsViewComponent } from './advanced-search-query-builder/advanced-search-query-builder-options-view/advanced-search-query-builder-options-view.component';
import {CdkConnectedOverlay, CdkOverlayOrigin} from "@angular/cdk/overlay";
import {CdkPortal} from "@angular/cdk/portal";
import {NewReportSidemenu} from "./new-report-sidemenu/new-report-sidemenu";
import { AdvancedSearchQueryBuilderValueInputComponent } from './advanced-search-query-builder/advanced-search-query-builder-field/advanced-search-query-builder-value-input/advanced-search-query-builder-value-input.component';
import { RouterModule } from '@angular/router';
import {SearchFilterMenuV2Component} from "./search-filter-menu-v2/search-filter-menu-v2.component";
import {
  FloatingFeatureToggleSwitchComponent
} from "../floating-feature-toggle-switch/floating-feature-toggle-switch.component";
import {SearchViewFullV2Component} from "./search-view-full-v2/search-view-full-v2.component";
import {
  SearchViewPaginationComponent
} from "./search-view-full-v2/search-view-pagination/search-view-pagination.component";
import {SearchResultViewV2Component} from "./search-view-full-v2/search-result-view-v2/search-result-view-v2.component";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        MatDialogModule,
        SharedModule,
        OverviewFieldsModule,
        MatTableModule,
        MatCheckboxModule,
        MatMenuModule,
        MatButtonModule,
        MatListModule,
        MatRadioModule,
        MatExpansionModule,
        MatRadioModule,
        MatTooltipModule,
        ReactiveFormsModule,
        DragDropModule,
        MatBottomSheetModule,
        MatIconModule,
        MatInputModule,
        ObjectEditModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        ScrollingModule,
        OperationsModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        MatButtonToggleModule,
        MatSortModule,
        MatSidenavModule,
        MatBadgeModule,
        MatTreeModule,
        MatTabsModule,
        RouterModule,
        SearchFilterChiplistComponent,
        CdkConnectedOverlay,
        CdkPortal,
        CdkOverlayOrigin,
        SearchFilterMenuV2Component,
        FloatingFeatureToggleSwitchComponent,
        SearchViewPaginationComponent,
        SearchResultViewV2Component
    ],
  exports: [
    ObjectNavigatorComponent,
    SearchQueryComponent,
    SearchViewSelectorComponent,
    SearchViewFullComponent,
    SearchMenuActionsComponent,
    SearchMenuSelectAllComponent,
    SearchMenuSortComponent,
    SearchFilterMenuSmallComponent,
    SearchResultPagerListComponent,
    ObjectSearchMenuComponent,
    SearchFocusMenuComponent
  ],
    declarations: [
        ObjectNavigatorComponent,
        SearchCollapseComponent,
        ObjectSearchMenuComponent,
        SearchQueryComponent,
        SearchFilterMenuComponent,
        SearchFilterMenuPathComponent,
        SearchFilterMenuItemComponent,
        SearchFilterGroupComponent,
        DateSearchFieldComponent,
        SearchFilterMenuSmallComponent,
        SearchResultViewComponent,
        SearchResultViewThumbnailComponent,
        SearchResultViewListSelectorComponent,
        SearchResultViewListThumbnailComponent,
        SearchResultViewListComponent,
        SearchResultViewGalleryComponent,
        SearchMenusComponent,
        SearchMenuSortComponent,
        SearchMenuViewSelectorComponent,
        SearchMenuFolderSettingsComponent,
        SearchMenuActionsComponent,
        SearchMenuSelectAllComponent,
        SearchMenuToggleRestrictionsComponent,
        SearchMenuSelectChosenComponent,
        SearchResultPagerComponent,
        SearchResultPagerListComponent,
        SearchViewSelectorComponent,
      SearchViewFullV2Component,

      NewReportSidemenu,
        SearchHeaderComponent,
        SideMenuToggleComponent,
        SearchFocusMenuComponent,
        SearchFocusEditDialogComponent,
        SearchViewFullComponent,
        SearchPageComponent,
        SearchQueryPathMenuComponent,
        SearchViewFolderHeaderComponent,
        SearchViewFullHeaderComponent,
        AdvancedSearchComponent,
        SearchViewFullHeaderComponent,
        SearchSelectionBasketComponent,
        SearchCategoryComponent,
        SearchFocusMenuPanelComponent,
        SearchResultViewThumbnailComponent,
        PanelLayoutComponent,
        ContentSectionComponent,
        FieldFilterComponent,
        FieldListComponent,
        QueryBuilderComponent,
        InputComponent,
        TextInputComponent,
        DateInputComponent,
        NumberInputComponent,
        BooleanInputComponent,
        SelectInputComponent,
        AdvancedSearchResultViewComponent,
        SelectObjectTypeButtonComponent,
        SearchFilterHierarchicComponent,
        SearchFilterHierarchyComponent,
        SearchHierarchicBreadCrumbsComponent,
        SearchFacetRangeComponent,
        SearchQueryFieldSearchComponent,
        SearchQueryAdvancedSearchComponent,
        AdvancedSearchQueryBuilderComponent,
      AdvancedSearchQueryBuilderGroupComponent,
      AdvancedSearchQueryBuilderFieldComponent,
      AdvancedSearchQueryBuilderInformationDialogComponent,
      AdvancedSearchQueryBuilderOptionsViewComponent,
      AdvancedSearchQueryBuilderValueInputComponent
    ],
    providers: []
})
export class ObjectSearchModule {
}
