<div
  class="thumbnail-list-item"
  [routerLink]="getRouterLink(item)"
  [ngClass]="{'thumbnail-list-item--selected' : searchContainer.selections.selected[item.artifact_id]}"
>
  <div
    class="thumbnail-list-item__checkbox-container"
    [ngClass]="{'thumbnail-list-item__checkbox-container--selected' : searchContainer.selections.selected[item.artifact_id]}"
  >
    <mat-checkbox
      (click)="select(item, $event)"
      [checked]="searchContainer.selections.selected[item.artifact_id]"
    />
  </div>

  @if (item.hasOwnProperty('$$imageUrl')) {
    <img
      [alt]=""
      class="thumbnail-list-item__image"
      [src]="item.$$imageUrl"
    />
  }
  @else {
    <div class="thumbnail-list-item__placeholder-container">
      <mat-icon class="thumbnail-list-item__placeholder">
        {{ getSymbolFromObjectType(item.object_type) }}
      </mat-icon>
    </div>
  }

  <div class="thumbnail-list-item__icon-container">
    <mat-icon>
      {{ getSymbolFromObjectType(item.object_type) }}
    </mat-icon>
  </div>

  <div class="thumbnail-list-item__content-area">
    <span class="thumbnail-list-item__title">
      {{ item.artifact_name }}
    </span>

    <div class="thumbnail-list-item__content">
      @for(field of getDisplayFields(item); track $index) {
        <div>
          <div>
            {{ field.title }}
          </div>
          <div>
            <app-overview-field
              [overviewField]="field"
              [viewName]="searchContainer.searchResultViewName"
              [toneDownTextColor]="true"
            ></app-overview-field>
          </div>
        </div>
      }
    </div>
  </div>
</div>

