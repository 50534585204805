import {Component, computed, HostListener, Inject, isDevMode, OnDestroy, OnInit, signal} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {LoginService} from './core/login.service';
import {UiToolsService} from './core/ui-tools.service';
import {CmsApiService} from './core/cms-api.service';
import {CmsErrorHandlerService} from './core/cms-error-handler.service';
import {CmsQueueService} from './core/cms-queue.service';
import {TranslateService} from '@ngx-translate/core';
import {CommonsService} from './core/commons.service';
import {Subscription} from 'rxjs';
import {UserData} from './core/definitions/user-data';
import {SwUpdate} from '@angular/service-worker';
import {PrimusBackendInstanceService} from './core/primus-backend-instance.service';
import {ModelsService} from './core/models.service';
import {SearchPageService} from './object-search/search-page.service';
import {JobStatusSubscriberService} from './core/jobstatussubcriber/job-status-subscriber.service';
import {UserCacheService} from './core/user-cache.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClientConfig} from './core/definitions/client-config';
import {PrimusRouterService} from './core/primus-router.service';
import {PrimusRouteService} from './core/primus-route.service';
import {distinctUntilChanged, take} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {AlertDialogComponent} from "./shared/alert-dialog/alert-dialog.component";
import {ConfirmDialogData} from "./object-edit/confirm-dialog/confirm-dialog.component";
import {SettingsService} from "./core/settings.service";
import {FieldConditionService} from "./core/field-condition.service";
import {ValueOptionService} from "./core/value-option.service";
import {FieldDateInfoService} from "./core/field-date-info.service";
import {FeatureFlagsService} from "./core/feature-flags.service";
import {SearchReferenceService} from "./core/search-reference.service";
import {HttpClient} from "@angular/common/http";
import {ModelStore} from "./core/ModelStore/ModelStore";
import {ReportUserGeneratedAdapter} from "./core/ModelStore/adapters/ReportUserGeneratedAdapter";
import {ReportViewTypeAdapter} from "./core/ModelStore/adapters/ReportViewTypeAdapter";
import {ReportUserGenerated} from "./core/ModelStore/models/ReportUserGenerated";
import {ReportUserTemplate} from "./core/ModelStore/models/ReportUserTemplate";
import {ReportViewType} from "./core/ModelStore/models/ReportViewType";
import {OperationModel} from "./core/ModelStore/models/Operation";
import {OperationStatus} from "./core/ModelStore/models/OperationStatus";
import {ReportUserTemplateAdapter} from "./core/ModelStore/adapters/ReportUserTemplateAdapter";
import {OperationStatusAdapter} from "./core/ModelStore/adapters/OperationStatusAdapter";
import {OperationAdapter} from "./core/ModelStore/adapters/OperationAdapter";
import {HibernationService} from './shared/hibernation.service';
import {ObjectPageNavigationStack} from './object-page-v2/object-page-navigation-stack';

const CHECK_SW_INTERVAL_MS = 15 * 60 * 1000;

interface modelResponse {
  entrypoints: string[];
  files: {
    string: string
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public currentStateName;
  public authErr: string;
  public txtLoadingContents: string;
  public txtToLogin: string;
  public hideLoader: boolean;

  private txtApiAuthenticationFailed: string;
  private txtNewVersionAvailable: string;
  private txtNewVersionDownload: string;

  private user: UserData;
  private authState = {
    user: false,
    api: false
  };
  private apiAuthRetryCounter = 0;
  private apiAuthRetryMaxCount = 10;
  private apiAuthRetryMs = 100;
  private authenticating = false;

  showApiSelector = signal(false);
  private newVersionAvailable: boolean;

  private subscription: Subscription = new Subscription();

  className: string;
  private languageSet = false;

  showFaq: boolean = false;

  // Loading state
  loadingUser = signal(true);
  loadingData = signal(true);
  checkingVersion = signal(false);
  checkingPermissions = signal(true);
  currentLoadingSteps = computed(() => {
    if (this.loadingUser()) {
      return ["TRANS__SPLASHSCREEN_LOADING_USER"]
    }
    if (this.checkingPermissions()) {
      return ["TRANS__SPLASHSCREEN_CHECKING_PERMISSIONS"]
    }
    // this step could take some time, so we make up some messages to show the user that actual work is happening.
    if (this.loadingData()) {
      return [
        "TRANS__SPLASHSCREEN_LOADING_DATA_1",
        "TRANS__SPLASHSCREEN_LOADING_DATA_2",
        "TRANS__SPLASHSCREEN_LOADING_DATA_3",
        "TRANS__SPLASHSCREEN_LOADING_DATA_4",
        "TRANS__SPLASHSCREEN_LOADING_DATA_5",
        "TRANS__SPLASHSCREEN_LOADING_DATA_6",
        "TRANS__SPLASHSCREEN_LOADING_DATA_7",
        "TRANS__SPLASHSCREEN_LOADING_DATA_8",
      ]
    }

    return null
  })

  private checkSwVersionInterval = null;

  constructor(
    public primusRouter: PrimusRouterService,
    private translate: TranslateService,
    private primusRoute: PrimusRouteService,
    private loginService: LoginService,
    private uiTools: UiToolsService,
    private cms: CmsApiService,
    private cmsQueue: CmsQueueService,
    private cmsErrorHandlerService: CmsErrorHandlerService,
    private commons: CommonsService,
    private models: ModelsService,
    private searchPageService: SearchPageService,
    private readonly primusBackendInstanceService: PrimusBackendInstanceService,
    private readonly swUpdate: SwUpdate,
    private readonly jobStatusSubscriberService: JobStatusSubscriberService,
    private userCacheService: UserCacheService,
    private settingsService: SettingsService,
    private fieldConditions: FieldConditionService,
    private valueOptions: ValueOptionService,
    private fieldDateInfo: FieldDateInfoService,
    private snackBar: MatSnackBar,
    private modalService: MatDialog,
    private featureFlagsService: FeatureFlagsService,
    private searchReferenceService: SearchReferenceService,
    private httpClient: HttpClient,
    private modelStore: ModelStore,
    private hibernationService: HibernationService,
    private _objectPageNavigationStack: ObjectPageNavigationStack,
    @Inject(DOCUMENT) private document: Document,
  ) {

    modelStore.registerModelFor('report_user_generated', 'report_user_generated', ReportUserGenerated);
    modelStore.registerAdapterFor('report_user_generated', new ReportUserGeneratedAdapter<ReportUserGenerated>('report_user_generated', 'report_user_generated'));

    modelStore.registerModelFor('report_view_type', 'report_view_types', ReportViewType);
    modelStore.registerAdapterFor('report_view_type', new ReportViewTypeAdapter<ReportViewType>('report_view_type', 'report_view_types'));

    modelStore.registerModelFor('report_user_template', 'report_user_templates', ReportUserTemplate);
    modelStore.registerAdapterFor('report_user_template', new ReportUserTemplateAdapter<ReportUserTemplate>('report_user_template', 'report_user_templates'));

    modelStore.registerModelFor('operation_status', 'operation_status', OperationStatus);
    modelStore.registerAdapterFor('operation_status', new OperationStatusAdapter('operation_status', 'operation_status'));

    modelStore.registerModelFor('operation', 'operations', OperationModel);
    modelStore.registerAdapterFor('operation', new OperationAdapter('operation', 'operations'));

    // Updates className with the latest className, updates only on change. This prevents NG0100 Error
    this.subscription.add(
      this.primusRouter.classNameObservable
        .pipe(distinctUntilChanged())
        .subscribe(className => {
          this.className = className;
        })
    )
    this.primusBackendInstanceService.responseStatusSubject
      .pipe(distinctUntilChanged())
      .subscribe((res: Response) => {

        // Reacts to 503 status.
       if(res.status === 503){
         const modalRef = this.modalService.open(AlertDialogComponent, {
           panelClass: 'alert-dialog',
           data: {
             modalTitle: 'TRANS__APP_COMPONENT__SERVICE_UNAVAILABLE',
             modalContent: 'TRANS__EXCEPTION_DEFAULT_503',
             modalButton: 'TRANS__ALERT_DIALOG__RELOAD',
           } as ConfirmDialogData
         })

         modalRef.afterClosed()
           .pipe(
             take(1),
           ).subscribe(() => {
             window.location.reload();
         })
       }
    });

    if (!window['modelViewer']) {
      this.httpClient.get<modelResponse>('https://modelviewer.ekultur.org/asset-manifest.json').subscribe(result => {
        result.entrypoints.forEach((entrypoint) => {
          if (entrypoint.endsWith('.js')) {
            const scriptElement = document.createElement('script');
            scriptElement.id = `modelviewer-microfrontend-${entrypoint}`;
            scriptElement.src = `https://modelviewer.ekultur.org/${entrypoint}`;
            document.head.appendChild(scriptElement);
          }
          /* fixme(2024-09-06): ModelViewer style sheet contains conflicting rules */
          // if (entrypoint.endsWith('.css')) {
          //   const linkElement = document.createElement('link');
          //   linkElement.id = `modelviewer-microfrontend-${entrypoint}`;
          //   linkElement.href = `https://modelviewer.ekultur.org/${entrypoint}`;
          //   linkElement.rel = 'stylesheet';
          //   document.head.appendChild(linkElement);
          // }
        });
      })
    }
  }

  private checkSwVersion() {
    if (this.swUpdate.isEnabled) {
      this.checkingVersion.set(true);
      this.swUpdate.checkForUpdate().then(() => {
        this.checkingVersion.set(false);
        if (this.newVersionAvailable) {
          this.showVersionSnackBar();
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.uiTools.setWindowSize();
  }

  ngOnInit(): void {
    console.log(window.screen.width);
    console.log(window.screen.height);
    this.setSomeTranslations();

    this.primusBackendInstanceService.authError.subscribe(err => {
      this.showApiSelector.set(false);
      this.authErr = err;
    });

    this.registerAuthSubscriber();
    this.registerCurrentUserSubscriber();

    if (this.apiAuthRetryCounter < this.apiAuthRetryMaxCount) {
      this.primusBackendInstanceService.setRefreshTokenSetAuthenticated().then();
    }

    this.checkSwVersion()
    this.checkSwVersionInterval = setInterval(() => {
      this.checkSwVersion()
    }, CHECK_SW_INTERVAL_MS)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearInterval(this.checkSwVersionInterval);
  }

  showFaqSidebar(show: boolean) {
    this.showFaq = show;
  }

  private async getApplicationSettings(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.authState?.api) {
        this.cmsQueue.runCmsFnWithQueue(this.cms.getClientConfig, undefined, false,
          (data: ClientConfig) => {
            // eslint-disable-next-line no-console
            if (isDevMode()) {
              console.debug('[APP] -- cms.getClientConfig() complete');
            }
            if (this.primusRoute.params.lang) {
              this.setTranslationLanguage(this.primusRoute.params.lang)
            } else {
              this.setTranslationLanguage(data.CLIENT_LANGUAGE);
            }
            this.commons.setObjectTypes(data.OBJECT_TYPES);
            this.settingsService.initSettings();
            this.featureFlagsService.initFeatureFlags();
            this.searchPageService.checkCreateSearchContainer();
            this.setSomeTranslations();
            this.fieldConditions.setFieldConditions();
            this.valueOptions.setAllValueOptions();
            this.fieldDateInfo.setFieldDateInfo();
            this.searchReferenceService.setSearchReferences();
            resolve();
          },
          e => {
            reject(e);
          });
      }
    });
  }

  private setTranslationLanguage(language) {
    this.translate.use(language);
    this.languageSet = true;

    this.document.documentElement.lang = language;
  }

  hasMainMenu() : boolean {
    const noMenuStates = ['authenticate', 'home.primus.full-screen', 'home.primus.reportPreview.report_id']; // , 'home.primus.annotate'
    return this.user && (!this.primusRouter.currentState() || noMenuStates.indexOf(this.primusRouter.currentState()) === -1);
  }

  registerClick(event) {
    this.uiTools.registerDocumentClick(event);
  }

  // showAuthErr(err: string) {
  //   return err;
  // }

  isAuthenticated() {
    return this.authState.user;
  }

  private init() {
    // eslint-disable-next-line no-console
    if (isDevMode()) {
      console.debug('[APP] -- init()');
    }

    this.currentStateName = this.primusRouter.currentState();
    this.primusRouter.navigationHandler(() => {
      this.currentStateName = this.primusRouter.currentState();
    })

    this.initServiceWorker();

    this.initApp().then();
  }

  private showVersionSnackBar() {
    const versionSnackBar = this.snackBar.open(this.txtNewVersionAvailable, this.txtNewVersionDownload, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });

    versionSnackBar.onAction().subscribe(() => {
      window.location.reload();
    });

  }

  private initServiceWorker() {
    try {
      console.log('[SW] Service-worker enabled: ', this.swUpdate.isEnabled);
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate().then(updateFound => {
          console.log('[SW] Update available: ', updateFound);
          this.newVersionAvailable = updateFound;
        });
      }
    } catch (e) {
      console.error('[SW] Unable to subscribe to service-worker registration', e);
    }
  }

  private async initApp() {
    /* eslint-disable no-console */
    if (isDevMode()) {
      console.debug('[APP] -- initApp()');
    }

    try {
      await this.loginService.getUserData();
    } catch (e) {
      console.error(e);
      this.authErr = e.error?.message || 'An unknown error occurred';
      return;
    }

    if (isDevMode()) {
      console.debug('[APP] -- models.getModelsAsync() complete');
    }
    await this.setPostLoginState();

    if (isDevMode()) {
      console.debug('[APP] -- post login state set');
    }

    this.jobStatusSubscriberService.startPolling();
  } /* eslint-enable no-console */

  private async setPostLoginState() {
    this.loadingData.set(false);

    if (this.newVersionAvailable) {
      this.showVersionSnackBar();
    }
  }

  private setSomeTranslations() {
    if (!this.languageSet) {
      let lang = 'no';
      if (navigator.language.toLowerCase().includes('sv')) {
        lang = 'sv';
      }
      this.translate.use(lang);
    }

    this.translate.get('TRANS__APP_COMPONENT__LOADING_CONTENTS').subscribe(s => {
      this.txtLoadingContents = s;
    });

    this.translate.get('TRANS__APP_COMPONENT__AUTHENTICATION_FAILED').subscribe(s => {
      this.txtApiAuthenticationFailed = s;
    });

    this.translate.get('TRANS__APP_COMPONENT__NEW_VERSION_AVAILABLE').subscribe(s => {
      this.txtNewVersionAvailable = s;
    });

    this.translate.get('TRANS__APP_COMPONENT__NEW_VERSION_DOWNLOAD').subscribe(s => {
      this.txtNewVersionDownload = s;
    });

    this.translate.get('TRANS__APP_COMPONENT__BACK_TO_LOGIN').subscribe(s => {
      this.txtToLogin = s;
    });
  }

  private retryApiAuth() {
    const museum = PrimusBackendInstanceService.getInstanceDetails();
    if (this.apiAuthRetryCounter > this.apiAuthRetryMaxCount) {
      // API-authentication failed after multiple retries, inform the user.
      this.authErr = `${museum.name} - ${this.txtApiAuthenticationFailed}`;
      this.hideLoader = true;
      this.showApiSelector.set(false);
      this.authenticating = false;
    } else {
      try {
        if (this.apiAuthRetryCounter > 0) {
          if (isDevMode()) {
            console.log(`[APP] -- Retrying API-auth in ${this.apiAuthRetryMs}, attempt: ${this.apiAuthRetryCounter}`);
          }
          setTimeout(() => {
            this.primusBackendInstanceService.apiAuthenticate(museum);
          }, this.apiAuthRetryMs);
        } else {
          this.primusBackendInstanceService.apiAuthenticate(museum);
        }
        this.apiAuthRetryCounter++;
      } catch (e) {
        console.error(e);
      }
    }
  }

  private registerCurrentUserSubscriber() {
    this.loginService.currentUser.subscribe(async (userData) => {
      if (!userData) {
        return;
      }

      this.user = userData;

      if (isDevMode()) {
        // eslint-disable-next-line no-console
        console.debug('[APP] -- currentUserObservable triggered');
      }

      // Attempt to initialize component only when user data has been loaded.
      this.cms.init((response, params) => {
        this.cmsErrorHandlerService.errHandler(response, params);
      });

      if (isDevMode()) {
        // eslint-disable-next-line no-console
        console.debug('[APP] -- cms.init() complete');
      }
    });
  }

  private registerAuthSubscriber() {
    this.primusBackendInstanceService.authenticated.subscribe(async (state) => {
      this.loadingUser.set(!state.user)
      this.checkingPermissions.set(!state.api);
      if (!state.user && !state.api) {
        return;
      }

      /* eslint-disable no-console */
      if (isDevMode()) {
        console.debug(`[APP] -- user authenticated: ${state.user}`);
        console.debug(`[APP] -- api authenticated: ${state.api}`);
        console.debug(`[APP] -- api auth failed: ${this.authErr}`);
      }
      /* eslint-enable no-console */

      this.authState = state;
      const museum = PrimusBackendInstanceService.getInstanceDetails();

      const showApiSelector = state.user && !state.api && !museum && !this.authenticating;
      const retry = (!state.user || !state.api || this.authenticating || this.authErr !== '') && !showApiSelector;
      const runInit = state.user && state.api && museum && !showApiSelector;

      if (retry) {
        this.showApiSelector.set(false);
      }

      if (showApiSelector) {
        this.showApiSelector.set(showApiSelector);
        this.authenticating = true;
        this.apiAuthRetryCounter++;
      } else if (retry) {
        this.retryApiAuth();
      } else if (typeof runInit !== 'undefined') {
        this.hideLoader = true;
        this.init();
      }
      try {
        await this.getApplicationSettings();
      } catch (e) {
        this.authErr = e.error?.message || 'An unknown error occurred';
      }
    });
  }

  readonly mainmenuV2 = this.featureFlagsService.getFeatureFlags().experimental.useNewMainMenu;
}
