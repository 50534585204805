import {Component, signal} from '@angular/core';
import {MatList, MatListItem, MatListItemLine, MatListItemTitle} from '@angular/material/list';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {MatFormField} from '@angular/material/form-field';
import {FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {RouterLink} from '@angular/router';
import {MatIcon} from '@angular/material/icon';
import {FeatureFlagsService} from '../../../core/feature-flags.service';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-admin-service-flags',
  standalone: true,
  imports: [MatList, MatSlideToggle, MatListItemTitle, MatListItemLine, MatFormField, ReactiveFormsModule, MatCheckbox, RouterLink, MatIcon, MatButton, MatListItem],
  templateUrl: './admin-service-flags.component.html',
  styleUrl: './admin-service-flags.component.scss'
})
export class AdminServiceFlagsComponent {

  notifications = signal([]);

  form = this.fb.group({
    temporary: this.fb.group({}), experimental: this.fb.group({
      useNewObjectPage: this.featureFlagService.getFeatureFlags().experimental.useNewObjectPage,
      useNewFilterMenu: this.featureFlagService.getFeatureFlags().experimental.useNewFilterMenu,
      useNewMainMenu: this.featureFlagService.getFeatureFlags().experimental.useNewMainMenu
    }), permanent: this.fb.group({})
  })

  constructor(private fb: FormBuilder, private featureFlagService: FeatureFlagsService) {
    this.featureFlagService.initFeatureFlags().then(() => {
      this.initFlags();
    })
  }

  async initFlags() {
    const overrides = this.featureFlagService.getOverrides();
    this.form = this.fb.group({
      temporary: this.fb.group({}), experimental: this.fb.group({
        useNewObjectPage: this.featureFlagService.getFeatureFlags().experimental.useNewObjectPage, ...overrides?.experimental,
        useNewFilterMenu: this.featureFlagService.getFeatureFlags().experimental.useNewFilterMenu, ...overrides?.experimental,
        useNewMainMenu: this.featureFlagService.getFeatureFlags().experimental.useNewMainMenu, ...overrides?.experimental
      }), permanent: this.fb.group({})
    });
  }

  saveFlags() {
    this.featureFlagService.setOverrides({
      ...this.form.getRawValue()
    })
    this.notify('Lagret!');
  }

  async resetFlags() {
    this.featureFlagService.clearOverrides();
    this.initFlags();
    this.notify('Flagg tilbakestilt')
  }

  private notify(message: string) {
    const id = (Math.random() * 100).toString(16)
    const notification = {
      id,
      message
    }
    this.notifications.update(arr => [...arr, notification])
    setTimeout(() => {
      this.notifications.update(arr => arr.filter(n => n.id !== id))
    }, 3000)
  }
}
