import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatFabButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {PrimusBackendInstanceService, PrimusInstanceDetails} from "../core/primus-backend-instance.service";
import {OperationService} from "../operations/operation.service";
import {UserData} from "../core/definitions/user-data";
import {OperationContainer} from "../core/definitions/operation-container";
import {OperationTarget} from "../core/definitions/operation-target.enum";
import {OperationDef} from "../core/definitions/operation-def";
import {LoginService} from "../core/login.service";
import {TranslateModule} from "@ngx-translate/core";
import {PrimusStateMapperService} from "../core/primus-state-mapper.service";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {PrimusRouterService} from "../core/primus-router.service";
import {PrimusRouteService} from "../core/primus-route.service";
import {NgClass} from "@angular/common";
import {MatTooltip} from "@angular/material/tooltip";
import {CmsApiService} from "../core/cms-api.service";
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";
import {JobStatusV2Component} from "./job-status-v2/job-status-v2.component";

export interface MenuEntry {
  name: string;
  icon: string;
  router_link: any[] | string;
  operation: OperationDef;
}

@Component({
  selector: 'app-main-menu-v2',
  standalone: true,
  imports: [
    MatIconButton,
    MatIcon,
    MatFabButton,
    TranslateModule,
    RouterLinkActive,
    RouterLink,
    NgClass,
    MatTooltip,
    MatMenu,
    MatMenuTrigger,
    JobStatusV2Component
  ],
  templateUrl: './main-menu-v2.component.html',
  styleUrl: './main-menu-v2.component.scss'
})
export class MainMenuV2Component implements OnInit {
  @Input() currentStateName: string;

  @Output() toggleFaqSidebar = new EventEmitter();

  museum: PrimusInstanceDetails;
  operationContainer: OperationContainer;
  primaryOperations: MenuEntry[];
  secondaryOperations: MenuEntry[];
  user: UserData;
  userImageUrl: string = '';

  constructor(
    private cmsApi: CmsApiService,
    private loginService: LoginService,
    private operationService: OperationService,
    private primusRouteService: PrimusRouteService,
    private primusRouterService: PrimusRouterService,
    public primusStateMapperService: PrimusStateMapperService
  ) {}

  ngOnInit() {
    this.museum = PrimusBackendInstanceService.getInstanceDetails();

    this.loginService.currentUser.subscribe(user => {
      this.initialize(user).then();
    });
  }

  getQueryParams() {
    // Deep copy params
    const params = JSON.parse(JSON.stringify(this.primusRouteService.params));
    // Remove the params under to prevent unintended consequences
    delete params.artifactId;
    delete params.parentId;
    delete params.rootObjId;
    delete params.rootObjType;
    return params;
  }

  getUserInitials(): string {
    let nameSplit = this.user.name.split(' ');
    let initials = '';

    if (nameSplit.length >= 2) {
      initials = nameSplit[0].charAt(0).toUpperCase() + nameSplit[nameSplit.length - 1].charAt(0).toUpperCase();
    }
    else {
      initials = nameSplit[0].charAt(0).toUpperCase();
    }

    return initials;
  }

  goToCurrentUser() {
    const state = 'user-profile';
    const params = {
      userId: this.user.artifact_id
    };
    this.primusRouterService.navigateState(state, params).then();
  }

  goToSearch() {
    this.primusRouterService.goBackToSearch().then();
  }

  showFaq(show: boolean): void {
    this.toggleFaqSidebar.emit(show);
  }

  private async initialize(user: UserData): Promise<void> {
    if (user) {
      this.user = user;
      this.operationContainer = await this.operationService.createOperationContainer(OperationTarget.MAIN_MENU_VIEW);

      await this.operationService.setOperations(this.operationContainer);

      this.sortMenuOperations();

      this.cmsApi.getArtifact({
        artifact_id: user.artifact_id,
        suppressErrHandler: true
      }).then((result) => {
        if(result.images && result.images.length > 0) {
          return this.cmsApi.getImageUrl({
            image_id: result.images[0].image_id,
            size: 'thumbnail'
          });
        }
      }).then((result) => {
        this.userImageUrl = result.url;
      })
    }
  }

  private sortMenuOperations(): void {
    // Reset sorted arrays to prevent null errors and duplicates
    this.primaryOperations = [];
    this.secondaryOperations = [];

    // Manually add static primary operations
    this.primaryOperations.push(
      {
        name: 'TRANS__MAIN_MENU__HOME_BUTTON',
        icon: 'home',
        router_link: '/home',
        operation: null
      },
      {
        name: 'TRANS__MAIN_MENU__SEARCH_BUTTON',
        icon: 'search',
        router_link: 'search',
        operation: null
      }
    );

    // Manually add static secondary operations
    this.secondaryOperations.push(
      {
        name: 'TRANS__MAIN_MENU__JOBS',
        icon: 'all_inboxes',
        router_link: 'operations',
        operation: null
      },
      {
        name: 'TRANS__MAIN_MENU__FAQ',
        icon: 'help_outline',
        router_link: 'faq',
        operation: null
      }
    )

    for (const operation of this.operationContainer.operations) {
      console.log(operation);
      switch(operation.operation_type_id) {
        case 'e0dbbea0-6c5f-45b8-929d-0fbc62f7d247':
        case '69b89b04-23f9-4d07-a3bf-85fd1ad6d26e':
        case '6ff12fc5-e4a6-426a-993b-ad3c40c1ab2e':
          this.primaryOperations.push({
            name: operation.menu_title,
            icon: operation.menu_icon,
            router_link: this.primusStateMapperService.routerLinkFromState(operation?.operation_steps[0]?.change_state[0].state_name),
            operation: operation
          });
          break;

        case '69b89b04-23f9-4d07-a3bf-85fd1ad6d06e':
          this.secondaryOperations.push({
            name: operation.menu_title,
            icon: operation.menu_icon,
            router_link: this.primusStateMapperService.routerLinkFromState(operation?.operation_steps[0]?.change_state[0].state_name),
            operation: operation
          });
          break;
      }
    }
  }
}

