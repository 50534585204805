<div class="job-status">
  <mat-tab-group (click)="$event.stopPropagation()">
    <mat-tab [label]="'TRANS__JOB_STATUS__YOUR_OPERATIONS' | translate">
      <div clasS="job-status__list">
        @for (message of getMessages(true); track $index) {
          <app-job-status-item
            [message]="message"
          />
        }
      </div>
    </mat-tab>

    <mat-tab [label]="'TRANS__JOB_STATUS__ALL_OPERATIONS' | translate">
      <div class="job-status__list">
        @for (message of getMessages(false); track $index) {
          <app-job-status-item
            [message]="message"
          />
        }
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
